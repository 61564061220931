@import 'src/styles/vars';

.materiallibrary {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    
    
}
.displayReferences{
    width: 100%;
    margin : 0 auto;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    &-grid{
        margin: 20px 0;
    }
    &--none{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__grid-settings{
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        &--size{
            display: flex;
            align-items: center;
            column-gap: 3px;
        }
    }
}
.paginate{
    display: flex;
    justify-content: center;
    width: 100%;
}

