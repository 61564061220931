@import 'src/styles/vars';

.modifypassworldmodal{
    position: absolute;
    margin-top: 20%;
    margin-left: 40%;
    max-width: 20rem;
    max-height: 30rem;
    background-color: white;
    border: 2px solid #000;
    padding: 4rem;
    display: flex;
    flex-direction: column;
}

// .input{
//     background-color: aqua;

// }

.ModifyPasswordModal-button {
    margin-top: 15px;
    color: whitesmoke !important;
    background-color: $orange !important;
    border: 0 !important;
    padding: 10px !important;
}
