@import 'src/styles/vars';

.menuadmin {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 100vw;
    padding: $gutter;
    background-color: #F3EFE3;

    &-logo {
        position: relative;

        &-img {
            max-width: 60px;
        }
    }

    &-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;
    }

    &-link {
        padding: $gutter;
        border-radius: 5px;
        color: black;
        text-decoration: none;
        transition: background-color ease-in-out 0.3s;
        border-left: 2px solid #F3EFE3;

        &:hover{
            cursor: pointer;
            background-color: rgba($color: black, $alpha: 0.3);
            border-radius: 5px;
            border-left: 2px solid #F3EFE3;
        }

        &--active {
            border-radius: 0;
            border-left: 2px solid black;
        }
    }

    &-logout {
        background-color: rgba($color: black, $alpha: 0.3);
        color: white;
        outline: none;
        border: none;
        padding: 0.5rem;
        border-radius: 5px;
        font-size: $font-size;
        font-weight: bold;
        transition: background-color ease-in-out 0.3s;

        &:hover {
            cursor: pointer;
            background-color: rgba($color: black, $alpha: 0.8);
        }
    }
}

