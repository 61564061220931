@import 'src/styles/vars';

.UsefullLinks {

        &-présentation {

            display: flex;

        &-texte {
            display: flex;
            --clay-background: rgb(238, 72, 66);
            --clay-shadow-inset-primary: -8px -8px 16px 0 rgba(0, 0, 0, 0.15);
            --clay-border-radius: 20px;
            box-sizing: border-box;
            padding: 30px;
            font-size: 1.3rem;
            margin: 50px auto;
            width: 75%;
            color: $font-color-2;
            justify-content: space-around;
            align-items: center;

            &-logo{
                margin-right: 10px;
                float: left;
                width: 150px;
            }
            }
        }

        &-container {
            border: 3px solid $font-color-2;
            border-radius: 10px;

        }

        &-links {

            background-image: url(../../../../public/icones/relier.png);
            padding: 30px 25%;
            background-repeat: no-repeat;
            background-position: left;
            background-color: rgb(0, 189, 182);
            filter: drop-shadow(3px 5px 5px rgba(0, 0, 0, 0.3));

            &-box {

                margin: 0 auto;
                display: flex;
                align-items: center;
                flex-direction: column;
                border: none;
            }

            &-title {
                margin: 0;
                font-size: 1.1rem;
                font-weight: 600;
                text-align: left;
            }

            &-ul {
                margin: 0 auto;
                width: fit-content;
                text-align: left;
                padding: 0px 0 10px 0;
            }

            &-li {
                display: flex;
                flex-direction: column;
                list-style-type: none;
                flex-wrap: wrap;
                align-content: center;
            }

            &-navlink {
                display: flex;
                align-items: center;
                color: $font-color;
                text-decoration: none;
                font-weight: bold;
                font-size: 1rem;
                padding: 5px;

                &:hover {
                    border-radius: 10px;
                    background-color: rgba(255, 251, 236, 0.308);
                    &.icon {
                        transform: rotate(90deg);
                    }
                }

                &-icon {
                    color: $font-color-2;
                    margin-right: 10px;
                }

                &-icon2 {
                    display: flex;
                    color: rgb(0, 255, 247);
                    align-items: flex-start;
                }

                &-icon3 {
                    margin-left: 5px;
                    display: flex;
                    color: #3b5998;
                    align-items: flex-start;
                }
            }
        }
}

.MuiAccordion-root {
    background-color: inherit  !important;
    border-left-color: none !important;

}

.css-1fjvggn-MuiPaper-root-MuiAccordion-root{
    border-top: 0  !important;
    border-bottom: 1.5px dashed rgba($color: #fffbec , $alpha:0.5) !important;
}

.css-1fjvggn-MuiPaper-root-MuiAccordion-root:first-child{
    border-radius: 10px 10px 0 0  !important;
}

.css-1fjvggn-MuiPaper-root-MuiAccordion-root:last-child{
    border-radius: 0 0 10px 10px !important;
}

.css-fv7qqu-MuiButtonBase-root-MuiAccordionSummary-root {
    align-items: baseline !important;
}

.css-a6zinj-MuiAccordionDetails-root {
    border-top: 0 !important;
}

@media all and (max-width: 1080px)
{
    .UsefullLinks { 
        &-links {
            padding: 30px 5%;
        }
        &-présentation {
        &-texte {
            flex-direction: column;
        }}
    }
}
