@import 'src/styles/vars';

.ref{
    background-color: $font-color-2;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: hidden;
    border-radius: 20px;
    padding:1rem;
    width: 50%;
    max-height: 90%;
    overflow: auto;
}
.cardmedia{
    background-color: aquamarine;
}

.reference-card {
    display: flex;
    flex-direction: column;

    &__name {
        font-size: 1rem!important;
        text-transform: capitalize!important;
        padding: 0 5px;
        margin: 15px auto 10px!important;
        text-overflow: ellipsis;
    }
    &__description {
        margin: 0 15px !important;
    }
    &__footer {
        display: flex;
        justify-content: space-around;
        margin-top: auto;
        margin-bottom: 15px;
    }
    &__content{
        height: 100%;
    }
    &__image-not-found{
        position: relative;
        background-color: lightgray;
        display: flex;
        justify-content: center;
        align-items: center;
        &--icon{
            opacity: 0.2;
            width : 2.5rem!important;
            height : 2.5rem!important;
        }
    }

    &__unavailable{
        display: flex;
        padding: 0 5px;
        font-size: 0.5rem!important;
        align-items: center;
        border: 1px solid black;
        border-radius: 1.5rem;
        &-text{
            font-size: 0.7rem !important;
        }
    }
    &__close {
        display: none;
    }
    &__caution {
        margin-bottom: 1rem !important;
    }
}

.transition {
    &-modal {
        &-title{
            text-transform: capitalize;
            margin-bottom: 10px!important;
        }
        &-description{
            font-size: 1rem!important;
            margin-top: 15px!important;
            margin-bottom: 30px!important;
        }
        &-inline{
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }
        &-valorisation{
            color : rgba(0,0,0,0.8);
            font-size: 1rem;
            border: 2px solid rgba(0,0,0,0.6);
            border-radius: 1.2rem;
            padding : 5px 15px;
            margin-right: 15px;
        }
        &-cardmedia{
            height:100%;
            width: 100%;

            max-height: 400px;
        }
        &-availability{
            border-radius: 1rem!important;
        }

    }
}

.MuiCardContent-root{
    position: relative;
    height: 100%;
}
@media all and (max-width: 1080px) {
    .ref{
        width: 95%;
    }
}

@media all and (max-width: 420px) {
    .ref{
        width: 100%;
        height: 100%;
        border-radius: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .reference-card {
        &__wrapper {
            // height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            overflow: auto;
            margin: auto 0;
        }
        &__close {
            display: flex;
            align-items: center;
            align-self: flex-end;
            border: solid 1px $orange;
            width: fit-content;
            border-radius: 1rem;
            padding: 0.2rem 0.5rem;
            // position: absolute;
            // top : 0;
            // left: 0;
            color: $orange;
            &--icon{
                color: $orange;
            }
        }
        
    }
    
}
