
.privacy {
    width: 90%;
    margin: auto;

    & h1{
        font-size: 1.7rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    & h2 {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    & span {
        display: block;
        font-size: 1.2rem;
        font-weight: bold;
    
    }
    & p {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }
}
