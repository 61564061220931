@import 'src/styles/vars';

.adminhomecard {
    border-radius: 10px;
    height: 250px;
    width: 250px;
    display: flex;
    flex-direction: column;

    &-title {
        color: $font-color-2;
    }

    &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $font-color;

        &-element {

            &--data {
                font-size: $font-size * 5;
                color: $font-color-2;
            }

            &--category {
                font-size: $font-size * 2;
            }
        }
    }

    &-booking {
        background-color: $orange;
    }

    &-user {
        background-color: $yellow
    }

    &-reference {
        background-color: $blue;
    }

}
