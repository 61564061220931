@import 'src/styles/vars';

.listofreferences{
    background-color: #cfe8fc ;
    height: 100vh;
    border:black solid 2rem;
}

.booking{
    &__container{
        margin: 0 auto;
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__accordion {
    width: 100%;
    }
}
