.loginForm {

    &-form{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-textfield{
        width: 100%;
    }

    &-submit{
        background-color: #ffc439;
        margin: 15px 0;
        border-radius: 10px;
        padding: 15px;
        border: 0;
        width: 80%;
        font-weight: 600;
    }

    &-submit:hover{
        cursor: pointer;
    }
}
