@import 'src/styles/vars';

.home-user {
    height: 100%;
   background-color: rgba($blue,0.4);
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 15px 0 30px;

   &-welcome-span{
       text-transform: capitalize;
   }

}
.loginuser-form {
    padding: 15px;
    border-radius: 10px;
    min-width: 25rem;
    margin: 0 auto;
    background-color: rgba(255, 251, 236, 1);
    row-gap: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginuser-textfield {
    background-color: white;
    margin-bottom: 15px;
}

.MuiTextField-root {
    margin-bottom: 15px;
}
