.adminUser {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.dashcontainer{
    flex-grow: 2;
    display: flex;
    transition: flex-grow 300ms,transform 300ms linear;
}

.dash-grid {
    flex-grow: 2;
    min-width: 420px;
    display: flex;
    flex-direction: column;
    transition: flex-grow 300ms,transform 300ms linear;
}
