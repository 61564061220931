@import 'src/styles/vars';

.hidden{
    opacity: 0;
}

.loginuser{
    display: flex;
    align-content: center;


    .css-tzssek-MuiSvgIcon-root {
        color: $font-color-2;
        width: 35px;
        height: 35px;
    }


    &-button{
        background-color: rgba(0, 0, 0, 0.35);
        border-radius: 10px;
        border: 0;
        transition: background-color 300ms;
    }

    &-button:hover {
        background-color: rgb(116, 116, 116);
        border-radius: 10px;
        cursor: pointer;
    }

    &-popover {
        max-width: 340px;
        padding: 20px 25px 25px;
        border-radius: 10px;
    }
    &-submit {
        margin-top: 15px!important;
    }
}

.loginuser2 {
    max-width: 340px;
    background-color: rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    border: 0;

    .css-tzssek-MuiSvgIcon-root {
        color: $font-color-2;
        width: 35px;
        height: 35px;
    }



    :hover {
        background-color: rgb(116, 116, 116);
        border-radius: 10px;
    }


    &-isActive{
    padding: 10px;
    background-color: $color;
    display: flex;
    flex-direction: row-reverse;

    flex-wrap: wrap;

    max-width: 300px;
    :hover {
        background-color: transparent;

    }

    .css-tzssek-MuiSvgIcon-root {
        background-color: $orange;
        border-radius: 10px;
        width :30px;
        height: 30px;
        color: $font-color-2;
    }



  }

    &-isConnect{

        padding:  0.6em;
        background-color: $color;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: nowrap;
        position: absolute;
        max-width: 20rem;
        margin-top:9rem;
        margin-left: 86%;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    &-btnopen{
        background-color: aqua;
        border: none;
        outline: none;
        background: none;
        color: $font-color;
        cursor: pointer;
        padding: 0.4rem;
        display: flex;
    }
    .gridContainer{
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .popover{
        text-shadow: rgb(0 0 0 / 30%) 5px 3px 5px;
    --clay-background: hsl(3, 94%, 53%, 0.9);
    --clay-border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15%;
    }
}

.css-1rcoy2u {
    margin-top: -10px !important;
    margin-bottom: 10px !important;
}




