.home{
    height: 100%;
    min-height: 100vh;
    // display: flex;
    // flex-direction: column;
    background-color: whitesmoke;
}

.content-container--max-width{
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 1rem;
}

@media all and (max-width: 420px)
{
    .content-container--max-width {
       padding : 0;
    }
}

