@import 'src/styles/vars';

.userbookings{
   background-color: rgba($blue,0.4);
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
}

.home-user{
    margin: auto 0;

}

.booking{
    &__title {
        background-color: $blue;
        color: $font-color-2;
        border-radius: 2rem;
        font-weight: 600;
        padding: 10px 17px;
        margin-bottom: 15px;
    }
    &__divider {
        width: 70%;
    }
    &__wrapper {
    padding : 15px 0 30px;
    margin: 10px 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    }
}


.list{
    width : 100%;
    max-width: 960px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem;
    padding-top: 15px;
    padding-bottom: 30px;
}
