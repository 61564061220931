@import 'src/styles/vars';



.inline{
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    padding: 15px!important;
    &-logo {
        display: none!important;

    }
    &-date{
        margin-right: 5px !important;
        flex-direction: row!important;

        span{
            text-transform: capitalize;
        }
    }
    &-block{
        margin-left: 8px;
        justify-content: flex-end!important;
    }
    &-hours{
        margin-left: 15px!important;
    }
    p{
        text-transform: capitalize;
    }

}

.permanency{
    padding:10px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    font-size: 1.2rem;
    --clay-background: rgb(238, 72, 66);
    --clay-border-radius: 20px;
    color: blanchedalmond;


    &-title {
        border: 3px solid $font-color-2;

        h2 {
            border: 3px solid $font-color-2;
            text-shadow: rgb(0 0 0 / 30%) 5px 3px 5px;
        }
    }

    &-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &-logo {
        max-width: 125px;
        filter: drop-shadow(rgb(0 0 0 / 30%) 5px 3px 5px);
    }

    &-date {
        margin-right: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-transform: capitalize;

        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 30%) 5px 3px 5px);
        border-radius: 10px;
        padding: 10px;
        border: 3px solid $font-color-2;
        font-size:1.5rem;

        &-none{
            text-transform: capitalize;
        }

    }
}
@media all and (max-width: 1080px)
{
    .permanency{
        &-block {
            flex-wrap: wrap;
            justify-content: center;
        }
        &-date {
            margin-right: 0;
        }
    }
}



