@import 'src/styles/vars';

.cardmedia{
    background-color: aquamarine;
}

.cartCard {
    width: 100%;
    display: flex;
    margin-bottom: 15px;
}

.cardContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
