@import 'src/styles/vars';

.booking {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: $gutter;
    justify-content: space-between;
    align-items: center;

    &-button {
        display: flex;
        width: 90%;
    }
    &-search {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 37px;

        &-element {
            width: calc( 50% - $gutter );
        }
    }
}
