@import 'src/styles/vars';

.shopmodal{
    background-color: whitesmoke;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:50%;
    border: hidden;
    border-radius: 20px;
    padding:2rem;
    height: 90%;
    overflow: scroll;
}

.currentItem{
    background-color: aquamarine;
}

.button-div{
    display: flex;
    justify-content: center;
}

@media all and (max-width: 1080px) {
    .shopmodal{
        width: 90%;
    }
}


