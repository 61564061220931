.swiper {
  width: 100%;
  height: 100%;
  min-height: 300px;
}

.swiper-wrapper {
    display: flex;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 300px;
  object-fit: cover;
}

body {
  background: #eee;
}
