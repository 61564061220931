@import 'src/styles/vars';

//! Code couleur?
.testcouleur {
   color:hsl(178, 100%, 37%, 1);
   color:hsl(36, 100%, 49%, 1);
   color:hsl(3, 94%, 53%, 1);
   color:hsl(353, 92%, 29%, 1);
   color: blanchedalmond;
}
.bandeau-présentation {
    background-color: hsl(178, 100%, 37%, 1);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: $gutter;
    &-texte {
        font-size: 1.5rem;
        max-width: 60%;
        color: blanchedalmond;
        text-shadow: rgb(0 0 0 / 30%) 5px 3px 5px;
        text-align: center;
        width:60%;
    }
    &-permanence {
        min-width: 275px;
    }
}

.fonctionnement {
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;

    &-title {
        margin-top: 0;
        font-size: 2.5rem;
        color: rgb(0, 0, 0);
        order: -1;
        flex: 1 0 100%;
     }

    &-content {
        max-width: 200px;
        color: rgb(0, 0, 0);
        font-weight:700;
        font-size: 1rem;
        align-items: baseline;
    }

    &-logo {
        width: 100px;
        height: 100px;
    }

    &-logo2 {
        margin-top: 20px;
        display: flex;
        width: 50px;
        height: 50px;
    }
}
.categoriesDisplay {
    background-color: hsl(36, 100%, 49%, 1);
    color: blanchedalmond;
    text-shadow: rgb(0 0 0 / 30%) 5px 3px 5px;
    font-weight:700;
    font-size: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
        // padding-bottom: 50px;
}

.categorie {

    margin: 10px;

    &-box {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-box--container {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 30px;
    }

    --clay-background: #ffebcd;
    --clay-shadow-inset-primary: -8px -8px 16px 0 rgba(0, 0, 0, 0.15);
    padding: 10px;

    &-title {
        font-size: 2.3rem;
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        flex: 1 0 100%;
        justify-content: center;
    }

    &-img {
        width: 100px;
        transition: transform .5s;
        filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.5));

        &:hover {
        transform: scale(2);
        filter: drop-shadow(3px 5px 5px rgba(0, 0, 0, 0.5));
        }
    }

}

.actu {
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: hsl(178, 100%, 37%, 1);

    &-title {
        margin-top: 0;
        margin-bottom: 30px;
        font-size: 2.5rem;
        color: blanchedalmond;
        text-shadow: rgba(0, 0, 0, 0.3) 5px 3px 5px;
        order: -1;
        flex: 1 0 100%;
    }

    &-post {
        margin: 20px;
        --clay-background: blanchedalmond;
        --clay-shadow-inset-primary: -8px -8px 16px 0 rgba(0, 0, 0, 0.15);
        color: rgb(0, 0, 0);
        align-items: baseline;
    }

    &-img {
        width: 300px;
        padding: 30px;
        filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.5));
    }
}

.contact-container {
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    &-iframe {
        height: 250px;
        padding: 30px;
        border-style: hidden;
        border-radius: 50px;
        filter: $drop-shadow;
    }

}
@media all and (max-width: 1080px)
{
    .bandeau-présentation {
        flex-direction: column;
        &-permanence {
            width: 50%;
        }
        &-texte {
            font-size: 1.2rem;
            margin-bottom: 5%;
        }
    }
    .fonctionnement {
        & p {
            margin-bottom: 5%;
        }
        &-logo2{
            display: none;
        }
    }
}
