@import 'src/styles/vars';

.admin {
    width: 100vw;
    padding: $gutter * 3;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
        font-size: $font-size * 3;
        max-width: 60%;
    }
}

@media all and (max-width: 1080px) {
    
    .admin {
       padding: 3rem; 
        &-title {
            font-size: 1.5rem;
            max-width: none;
        }
    }
}
