@import 'src/styles/vars';

.who {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    background-color: $orange;
    margin-bottom: 30px;
    padding: 30px;

    &-title {
        &-logo {
            width: 50px;
            margin-right: 30px;
        }
    }
    p {
        display: flex;
        flex-flow: row nowrap;

        font-size: 1.3rem;
        margin: 0 auto;
        width: 60vw;
        color: $font-color-2;
        align-items: center;
    }
}
.everybody{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    &-column {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &-content{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    p {
        color: $font-color;
        margin: 0;
        text-align: center;
        font-size: 1.2rem;
        font-weight: 600;
    }
}
.wordcloud{
    max-height: 400px;
}
.simple-cloud {

    width: 50%;
    line-height: 3rem;
    span {
        margin-right: 20px;
    }
}
.content-actions{
    background-color: $font-color-2;
    padding: 30px;
    margin-bottom: 30px;

}

.actions {
    display: flex;
    flex-flow: column wrap;
    width: 50vw;
    margin: 0 auto;

    &-li{
        margin: 20px 0 20px 0;
        display: flex;
        align-items: flex-end;
        justify-content: baseline;


        p {
            color: $font-color;
            margin: 0;
            text-align: left;
            font-size: 1.2rem;
            font-weight: 600;
        }

        &-icons {
            filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.3));
            margin-right: 20px;
            width: 60px;
        }
    }
}
.about-title {
        border-bottom: 3px solid $font-color ;
        padding-bottom: 20px;
        width: 50vw;
        margin: 30px auto;
        margin-bottom: 20px;
        color: $font-color;
        font-size: 1.5rem;

        &--white {
            color: $font-color-2;
            border-bottom: 3px solid $font-color-2 ;
        }
}

.about-philo {
    display: flex;
    flex-flow: row wrap;
    background-color: $blue;

    &-icon {
        margin-bottom: -5px; 
        color: $font-color-2;
    }

    p {
        align-items: center;
        font-weight: 600;
        margin: 0 auto;
        padding-bottom: 30px;
        width: 70vw;
        color: $font-color-2;
        font-size: 1.2rem;
        text-align: center;
    }
}
.actions-passées {
    margin-bottom: 30px;

    p {
        font-weight: 600;
        margin: 0 auto;
        padding-bottom: 30px;
        width: 50vw;
        color: $font-color;
        font-size: 1.2rem;
        text-align: center;
    }

    &-diapos {

        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
        width: 70%;
        justify-content: center;
    }

    &-body {
        margin: 10px;
        width: 250px;
        --clay-background: rgb(250, 150, 0);
        --clay-border-radius: 10px;
        padding: 5px;
        text-decoration: none!important;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: rgb(255, 178, 62);
        }

    &-link {
        text-decoration: none!important;
        display: flex;
        flex-direction: row;
        align-items: center;


        p {
            padding: 0;
            text-transform: uppercase;
            color: $font-color;
            font-weight: 600;
            font-size: 1rem;
        }

        &-button {
            color: $font-color-2;
        }
    }

    &-pdf-modal {
            position:relative;
            top: 10%;
            left: 10%;
            width: 75vw;
            height: 75vh;
            border-radius: 10px;
        }
    }
}

@media all and (max-width: 810px)
{   .who   {

    p {
    display: block;
}
}   .actions-li {

    p {text-align: center;
    }

    &-icons{
    display: none;
    }
}
}



