.datagrid{
    &__availableSpace {
    flex-grow: 2;
    display: flex;
    height: 100%;
}
    &__container{
        flex-grow: 1;
    }
}
