@import 'src/styles/vars';

.section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &-head {
        display: flex;
        width: 100%;
        &-ask {
            align-self: center;
            margin-right: 10px;
        }
    }

    &-element {
        width: 100%;
        margin: $gutter / 2;

        &-title {
            font-size: $font-size * 3;
        }

        &-modal {
            display: flex;
            width: 100%;
            & div {
                display: flex;
                align-items: center;
            }
        }

        &-add {
            width: 100px;
            height: 50px;
        }
    }
}
