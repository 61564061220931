@import 'src/styles/vars';

.adminhome {
    padding: $gutter;

    &-element {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 auto;
        justify-content: center;

        &-item {
            margin: $gutter;
        }
    }

    &-title {
        font-size: 2em;
    }

    .grid-container {
        display: flex;
    }
}
