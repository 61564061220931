@import 'src/styles/vars';

.listofreferences{
    background-color: #cfe8fc ;
    height: 100vh;
    border:black solid 2rem;
}

.gridList{
    height: 100%;
    gap: 25px;
    align-items: flex-start;
    align-content: space-around;
    &-empty{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 125px;
    }
}
