.contentpage {
    height: 100%;
    margin: 0 auto;
}

.contentpage > *:first-child {
    min-height: calc(100vh - 160px);
}


