@import 'src/styles/vars';

.contact {

    &-header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
    }

    img {
        margin-right: 20px;
        width: 50px;
        height: 50px;
        filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.5));
    }
}

.contact-form-text {
    background-color: white ;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    height: auto !important;
}
.ohnohoney{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
}


