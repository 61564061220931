@import 'src/styles/vars';

.perm {
    margin: 30px;
    display: flex;
    justify-content: center;
}

.infos-header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-evenly;
    background-color: hsl(178, 100%, 37%, 1);

        img {
            width:400px;
        }


    &-adresse {
        display: flex;
        flex-flow: column wrap;
        text-align: left;
        color: $font-color-2;
        font-size: 1.2rem;
        margin: 30px;

        h2 {
            display: flex;
            flex-flow: row nowrap ;
            align-items:flex-end ;
            text-shadow: rgb(0 0 0 / 30%) 0px 0px 5px;
        }

        img {
            width: 50px;
            margin-right: 20px;
            filter: $drop-shadow
        }



        a {
            text-decoration: none;
            color: $font-color-2;

        }
    }

    &-permap {
        display: flex;
        margin: 30px ;
        flex-direction: column;
        flex-wrap: wrap;
     }

    &-map {
        margin-top: 30px;
        border-style: hidden;
        border-radius: 20px;
        filter: $drop-shadow;
    }
}

.contact {
    align-items: center;
    margin-bottom:  30px;
    width: min-content;
}

.become {
    margin: 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;

    &-adhérent {
        max-width: 500px;
    }

    &-bénévole{
        max-width: 500px;

    }


    &-bénévole-body {
        margin: 30px;
        display: flex;
        flex-flow: column wrap;
        text-align: left;
    }

    &-cat{
        margin: 50px;
        max-width: fit-content;
        flex: 50%;
        justify-content: space-between;
        border: 3px solid $blue;
        border-radius: 20px;
        transition: filter 0.5s;

    }
    &-title {

        margin: 30px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        padding: 10px;
        background-color: $blue;

        h2 {

            color: $font-color-2;
            filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.5));
        }

        &-img {
            width: 50px;
            height: 50px;
            margin: 10px;
            filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.5));

        }
    }

    &-body {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;

        &-description {
            margin: 0 30px 50px 30px;
            text-align: left;
        }

        &-docs {
            width: 100%;
            margin: 0 10px 0 20px;

            a {
                width: 100%;
                display: flex;
                align-items: center;
            }

            display: flex;
            flex-flow: row nowrap;
        }

        &-list {

            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            margin-block-start: 0 !important;
            margin-block-end: 0 !important;
            margin-inline-start: 0px !important;
            margin-inline-end: 0px !important;
            padding-inline-start: 0 !important;

            li {
                --clay-shadow-inset-primary:-8px -8px 10px 0 rgba(0, 0, 0, 0.15);
                margin-bottom: 20px;
                background-color: rgb(238, 72, 66);
                border-radius: 10px;
                display: flex;
                justify-content: space-between;
                list-style-type: none;
                transition: background-color 0.3s;

                &:hover {
                    background-color: rgb(241, 18, 10);
                }
            }

        }

        &-link {
            text-decoration: none!important;
            // color: $font-color-2 !important;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            p {

                text-transform: uppercase;
                color: $font-color-2;
                font-weight: 600;
                font-size: 0.9rem;

            }


            &-button {
                color: $font-color-2;
            }
        }

        &-pdf-modal {
                position:relative;
                top: 10%;
                left: 10%;
                width: 75vw;
                height: 75vh;
                border-radius: 10px;
            }

    }
}

.infos-bottom {
    padding: 50px;
    background-color: $font-color-2;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;

    &-right {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    &-actus-logo {
        width: 50px;
            height: 50px;
            margin-right: 20px;
    }

    &-facebook {
        align-items: center;
        &-button {
            border-radius: 10px;
            border-style: hidden;
            background-color: rgb(58, 88, 158);
            transition: filter 0.3s;

            &:hover {
                filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
            }

        }

        a {
            font-size: 1rem;
            font-weight: 600;
            text-decoration: none;
            color: white;
            text-transform: uppercase;
            margin: 5px 15px 5px 0;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
        }
        &-logo {
            width: 50px;
            margin: 5px 20px 5px 0;
        }
    }

    &-paypal-content {
        margin: 30px;
    }

    h2 {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        justify-content: center;
    }

    &-paypal-logo {
        width: 50px;
        height: 50px;
        margin-right: 20px;
        transition: filter 0.3s;
    }
}

@media all and (max-width: 810px)
{ .become  {
    width: 100vw;
    margin: 0;
} .infos-header-adresse {
    text-align: center;
    align-items:center;
}



}
