@import 'src/styles/vars';

.adminpermanency{
    display: flex;
    flex-direction: column;
    border: 3px solid $orange;
    border-radius: 10px;
    height: 250px;
    width: 250px;
    align-items: center;

    &-title {
        color: $orange;
    }

    &-element {
        margin: 2rem 2rem 1rem 2rem;
    }

    &-button {
        margin-top: $gutter;
        width: 50%
    }
}



