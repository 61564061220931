@import 'src/styles/vars';

.home-user {
    // Pas beau je sais AP 06/08/2022
    min-height: calc(100vh - 209px);
}


.css-s7d5x3-MuiButtonGroup-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border-radius: 4px;
    box-shadow: none !important;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;

}

.menu-user-button1  {
    text-decoration: none;
}

.css-s7d5x3-MuiButtonGroup-root .MuiButtonGroup-grouped {
    margin-bottom: 20px;
    min-width: 40px;
    width: 300px;
    box-shadow: none;
}
