@import 'src/styles/vars';

.error{
    display: flex;
    background: linear-gradient(#00bdb6, #ffffff);
    height: calc(100vh - 80px);
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;

    &-title {
        max-width: 80vw;
        margin: 0;
        color: $font-color;
        padding-top: 30px;
    }
        a{
        margin-top: 30px;
        background-color: $orange;
        padding: 10px 20px 10px 20px;
        border-radius: 10px;
        border: none;
        font-size: 1.2rem;
            color: $font-color-2;
        text-decoration: none ;
        }

    &-404 {
        max-width: 50vw;
    }
}
