@import 'src/styles/vars';

.footer {
    // display: flex;
    width: 100%;
    height: 80px;
    // margin-top: auto;
    // align-items: center;
    // justify-content: space-between;
    background:$font-color-2;
    filter: $drop-shadow;

    &-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        max-width: 1900px;
        margin: 0 auto;
    }

    &-facebook-logo {
        height: 40px;
        margin: auto $gutter;
        border-radius: 8px;
        filter: $drop-shadow;
    }

    &-left {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        color: $font-color;
        font-size: 1.1rem;

    }

    &-right {
        display: flex;
        align-items: center;
    }

    &-logo {
        font-size: 1.2rem;
        margin: auto $gutter;
        height: 60px;
    }

    &-texte {
        margin: 30px;
        font-size: 1.1rem;
        text-decoration: none;
        color: $font-color;
    }
}

@media all and (max-width: 810px)
  {
.footer {
    height: 100%;
    flex-direction: column;
    &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-texte{
        margin: 10px;
        }

    &-left {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
    }
    &-right {
        display: flex;
        flex-direction: column;
    }
  }
  }
