$gutter: 2rem;
$font-size: 1em;
$color: #fefefe;

$blue:rgb(0, 189, 182);
$yellow:rgb(250, 150, 0);
$orange:rgb(238, 72, 66);
$red:rgb(142, 6, 22);
$font-color-2: rgb(255, 251, 236);
$font-color: rgb(51, 51, 51);
$drop-shadow: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.3));
@mixin border {
        filter: drop-shadow(3px 5px 5px rgba(0, 0, 0, 0.2));
        border-radius: 10px;
        padding: 10px;
        border: 3px solid;
        color: $theme
}


