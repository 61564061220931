@import 'src/styles/vars';

.updatereference-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    max-height: 90%;
    padding: $gutter;
    width: 70%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    outline: none;
    overflow: auto;

    &-header {
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;


        &-title {
            flex: 1;
            text-align: left;
            position: relative;
            text-transform: uppercase;
        }
    }

    &-inputs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 80%;
        padding: $gutter;

        &-item {
            width: 45%;
        }
    }

    &-articles {
        width: 100%;
    }

    &-footer {
        margin-top: $gutter;
        & button {
            margin-right: 1rem;
        }
    }

    &--open {
        margin: $gutter;
    }
}
