.button-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    & button{
        margin-right: 1rem;
    }
}
